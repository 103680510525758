<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                class="tw-flex tw-items-center tw-justify-between"
                cols="12"
                sm="6"
                order="2"
                order-sm="1">
                <h1 class="tw-text-xl tw-font-bold sm:tw-text-2xl">{{ central?.name }}</h1>
            </v-col>
            <v-col
                order="1"
                order-sm="2"
                cols="12"
                sm="6"
                class="tw-flex tw-items-center tw-justify-end">
                <div class="tw-flex tw-flex-row tw-gap-4">
                    <p
                        :class="[statusDetails?.twBgColor, statusDetails?.twColor]"
                        class="tw-rounded-lg tw-px-4 tw-py-2 tw-font-semibold">
                        {{ statusDetails?.text }}
                    </p>
                    <v-menu open-on-hover open-on-click open-on-focus>
                        <template v-slot:activator="{ props }">
                            <v-btn
                                color="primary"
                                icon="mdi-dots-vertical"
                                size="small"
                                class="tw-rounded-lg"
                                v-bind="props">
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item
                                value="reset"
                                color="primary"
                                @click="onReset">
                                <template v-slot:prepend>
                                    <v-icon
                                        color="red"
                                        icon="mdi-backup-restore"></v-icon>
                                </template>
                                <v-list-item-title class="tw-font-medium">{{ $t('views.centrals.reset') }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                value="silence"
                                @click="onSilence">
                                <template v-slot:prepend>
                                    <v-icon icon="mdi-bell-off-outline"></v-icon>
                                </template>
                                <v-list-item-title class="tw-font-medium">{{ $t('views.centrals.silence') }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-col>
        </v-row>
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                sm="12"
                class="tw-gap-2 max-lg:tw-grid max-lg:tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-flex">
                <v-btn
                    v-for="(item, index) in navItems"
                    :key="index"
                    :to="item.to"
                    min-width="128"
                    size="large"
                    :color="item.to.name == $route.name ? 'primary' : 'gray-200'"
                    >{{ item.title }}</v-btn
                >
            </v-col>
            <v-col
                v-if="isLoading"
                cols="12">
                <v-progress-linear
                    :active="isLoading"
                    color="primary"
                    indeterminate></v-progress-linear>
            </v-col>
        </v-row>
        <router-view v-if="central" />
    </v-container>
</template>

<script setup lang="ts">
    import { ref, watch, computed, provide } from 'vue';
    import { useRoute } from 'vue-router';
    import { ContentType } from '@/contentTypes';
    import { useI18n } from 'vue-i18n';
    import { getCentral, resetCentral, silenceCentral } from '@/api/centrals';
    import { useAlert } from '../../composables/useAlert';
    import useError from '@/composables/useError';
    import { useConfig } from '@/composables/useConfig';
    import { centralStatus } from '../../config/config';
    import { useSocket } from '@/composables/useSocket';

    const $route = useRoute();
    const $alert = useAlert();
    const $error = useError();
    const $config = useConfig();
    const $socket = useSocket();
    const { t } = useI18n();

    const central = ref<ContentType<'Central'>>();
    const isLoading = ref(false);
    const confirmationOpen = ref(false);

    const navItems = computed(() => {
        return [
            {
                title: t('views.centrals.shared.buttons.zones'),
                to: { name: 'Central-Zones', params: { id: $route.params.id } },
            },
            {
                title: t('views.centrals.shared.buttons.loops'),
                to: { name: 'Central-Loops', params: { id: $route.params.id } },
            },
            {
                title: t('views.centrals.shared.buttons.devices'),
                to: { name: 'Central-Devices', params: { id: $route.params.id } },
            },
            {
                title: t('views.centrals.shared.buttons.configurations'),
                to: { name: 'Central-Config', params: { id: $route.params.id } },
            },
        ];
    });

    async function init() {
        isLoading.value = true;
        try {
            $socket.emit('central:zones', $route.params.id);
            const { data } = await getCentral($route.params.id as string);
            central.value = data.data;
        } catch (error) {
            $alert.showAlert({
                text: $error.getErrorMessage(error),
                type: 'error',
            });
        } finally {
            isLoading.value = false;
        }
    }

    const statusDetails = computed(() => {
        return $config.getStatus.value(central.value?.status as keyof typeof centralStatus);
    });

    $socket.on('central:status', (data: { centralId: number; status: string }) => {
        if (data.centralId == central.value?.id) {
            central.value!.status = data.status;
        }
    });

    async function onReset() {
        isLoading.value = true;
        resetCentral(central.value?.serialNumber as string)
            .then(() => {
                $alert.showAlert({
                    text: t('views.centrals.resetSuccess'),
                    type: 'success',
                });
            })
            .catch((error) => {
                $alert.showAlert({
                    text: t('views.centrals.resetError'),
                    type: 'error',
                });
            })
            .finally(() => {
                isLoading.value = false;
                confirmationOpen.value = false;
            });
    }

    async function onSilence() {
        isLoading.value = true;
        silenceCentral(central.value?.serialNumber as string)
            .then(() => {
                $alert.showAlert({
                    text: t('views.centrals.silenceSuccess'),
                    type: 'success',
                });
            })
            .catch((error) => {
                $alert.showAlert({
                    text: t('views.centrals.silenceError'),
                    type: 'error',
                });
            })
            .finally(() => {
                isLoading.value = false;
            });
    }

    watch(
        () => $route.params.id,
        () => {
            init();
        },
        {
            immediate: true,
        },
    );

    provide('central', central);
</script>

<style scoped></style>
